import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Mail';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import AlertTitle from '@mui/material/AlertTitle';

import {COLORS} from '../constants/colors';

export default function Footer(props) {
    const {responsive, short} = props;
    const formObject = {
        contactName: '',
        contactCompany: '',
        contactEmail: '',
        otherDescription: '',
        services: ''
    }
    const [alertOpen, setAlertOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [send,setSend] = useState(0);
    const [selectedServices, setSelectedServices] = useState([])
    const [disableSlider, setDisableSlider] = useState(true)
    const [contactForm,setContactForm] = useState(formObject)

    useEffect( ()=>{
        
        if (
            contactForm.contactName && 
            contactForm.contactCompany && 
            contactForm.contactEmail && 
            contactForm.services.length > 0
            ){setDisableSlider(false)}
            else{setDisableSlider(true)}
    },[contactForm])

    useEffect( ()=>{
        setContactForm({
            ...contactForm,
            services:selectedServices})

    },[selectedServices])
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxWidth: "90%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        margin:"5px",
    };
    const serviceList = ["Development","Process Review","Project Management","Integration","Documentation","Advisory"]

    const toggleCheck = (val) => {
        let value = val.target.name
        if (selectedServices.includes(value)){
            setSelectedServices(selectedServices.filter(service => service !== value))
        }
        else {
            setSelectedServices([...selectedServices,value])}
    }

    const sendMessage = async () =>{
        console.log('sendMessage',contactForm)
        
        await fetch('/contact/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contactForm)
        });
        setSend(-1);
        setAlertOpen(true)
        setOpen(false)
        setSelectedServices([]);
        setContactForm(formObject);
        // const content = await rawResponse.json();
        
    }

    return (
        <Box sx={{ zIndex:99, position: 'fixed', bottom:0, width:responsive ? '100%' : '50vw'}}>
            <BottomNavigation
                showLabels
                sx={{ backgroundColor: COLORS.litDarkBlue }}
                onChange={() => {
                    setOpen(true);
                }}
                >
                <BottomNavigationAction label={
                    <Typography gutterBottom variant="body1" component="div" style={{fontFamily:"Iceland"}}>
                        Contact Us
                    </Typography>} 
                    style={{color: COLORS.litBlue}} icon={<RestoreIcon sx={{ color: COLORS.litBlue }} />} />

            </BottomNavigation>
        
            <Box sx={{ width: '-1' }} style={{top: '30vh', left:'40%', position:"fixed"}}>
                <Collapse in={alertOpen}>
                    
                    <Alert
                        action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                        setAlertOpen(false);
                        }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        <AlertTitle>                        
                            <Typography gutterBottom variant={responsive || short  ? "body1":"h4"} component="div" style={{fontFamily:"Iceland"}}>
                                Success
                            </Typography>
                        </AlertTitle>
                        <Typography gutterBottom variant={responsive || short ? "body2":"h5"} component="div" style={{fontFamily:"Iceland"}}>
                            Your contact request has been submitted successsfully!<br/>
                            Thank you!
                        </Typography>
                    </Alert>
                </Collapse>
            </Box>
            <Modal
                open={open}
                onClose={()=>{setOpen(false)}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>

                <Typography onClick={()=>{setOpen(false)}} gutterBottom variant="h4" component="div" style={{ textAlign:"right",fontFamily:"Iceland"}}>
                        X
                </Typography>

                {send === -1 ?                      
                    <Typography gutterBottom variant="h5" component="div" style={{ fontFamily:"Iceland"}}>
                        Your contact request has been successfully submitted!
                    </Typography> : 
                    <Stack spacing={2}>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily:"Iceland"}}>
                            Contact Us
                        </Typography>
                        <TextField
                            onChange={(event)=>{
                                let val = event.target.value
                                setContactForm({...contactForm,contactName:val}
                                    )}}
                            style={{fontFamily:"Iceland",maxWidth:"100%",}}
                            id="yourName"
                            label={<Typography style={{fontFamily:"Iceland"}}>
                                    {"Your Name*"}
                                </Typography>}
                            defaultValue=""
                            />

                        <TextField
                            onChange={(event)=>{
                                let val = event.target.value
                                setContactForm({...contactForm,
                                    contactCompany:val}
                                    )}
                                }
                            style={{fontFamily:"Iceland"}}
                            id="companyName"
                            label={<Typography style={{fontFamily:"Iceland"}}>
                                    {"Company Name*"}
                                </Typography>}
                            defaultValue=""
                            />

                        <TextField
                            onChange={(event)=>{
                                let val = event.target.value
                                setContactForm({...contactForm,contactEmail:val})}}
                            style={{fontFamily:"Iceland"}}
                            id="contactEmail"
                            label={<Typography style={{fontFamily:"Iceland"}}>
                                    {"Contact Email*"}
                                </Typography>}
                            defaultValue=""
                            />
                            
                        <FormGroup>
                            {
                                serviceList.map(s => {
                                    return <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={selectedServices.includes(s)} 
                                                onChange={toggleCheck} name={s} />} 
                                        label={
                                            <Typography style={{fontFamily:"Iceland"}}>
                                                {s}
                                            </Typography>
                                        } />
                                })
                            }
                            
                        </FormGroup>



                        <Stack alignContent={'center'} alignItems={'center'}>
                            <Typography variant="h4" component="div" style={{fontFamily:"Iceland"}}>
                                {"Slide to the right to send --->"}
                            </Typography>
                            <Slider disabled={disableSlider} 
                                min={1} 
                                max={2} 
                                style={{width:"50%", 
                                    color:disableSlider? "light gray" :
                                        COLORS.litGreen}} 
                                aria-label="Volume" 
                                value={send} 
                                onChange={(event,val)=>{
                                    if (val === 2){
                                        sendMessage()
                                    }

                                    setSend(val);
                                    }} 
                                />
                        </Stack>
                    </Stack>
                }
                </Box>
            </Modal>
        </Box>
    );
}