import {useState, useRef} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import homeBlerbs from '../constants/blerbs/home';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import honesty from '../assets/img/honesty.png';
import quality from '../assets/img/quality.png'
import simplicity from '../assets/img/simplicity.png'

import logo from "../assets/img/lit_logo_transparent_full.png";
import {COLORS} from '../constants/colors';
export default function Home(props) {
  const responsive = props.responsive;
  const short = props.short;
  const developmentRef = useRef(null);
  const processRef = useRef(null);
  const projectRef = useRef(null);
  const [clickedCard,setClickedCard] = useState();
  const [activeRef,setActiveRef] = useState();

  const cardTitles=[
    {
      title:'Honesty',
      blerb:homeBlerbs.honesty,
      img:honesty
    },
    {
      title:'Quality',
      blerb:homeBlerbs.quality,
      img:quality
    },
    {
      title:'Simplicity',
      blerb:homeBlerbs.simplicity,
      img:simplicity
    },
  ]

  const getCards = (card) => {
    return(
      responsive?
      <div>
        <Card sx={{ width: '100%' }}>
          <CardActionArea
            >
            <CardMedia
              component="img"
              height="100"
              image={card.img}
              alt="Development"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" style={{fontFamily:"Iceland"}}>
                {card.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{fontFamily:"Iceland"}}>
                {card.blerb}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
      :
      <Card sx={{ width: '30%' }} id={card.title+"card"}>
            <CardMedia
              id={card.title+"media"}
              component="img"
              height="140"
              image={card.img}
              alt="Development"
            />
            <CardContent>
              <Typography gutterBottom variant={responsive || short ? "body1":"h4"} component="div" style={{fontFamily:"Iceland"}}>
                {card.title}
              </Typography>
              <Typography variant={responsive || short ? "body2":"body1"} color="text.secondary" style={{fontFamily:"Iceland"}}>
                {card.blerb}
              </Typography>
            </CardContent>
        </Card>
    )
  }

  return (
    <Stack 
      justifyContent="center" 
      alignItems="center" 
      spacing={2}>

      <Card sx={{ 
        padding:responsive || short ?'1px':'10px',
        
        width:responsive ? '99vw' : '50vw', 
        backgroundColor: '#595959' 
        }}>

          {!responsive && short ?
            null: 
            <CardMedia
              component="img"
              image={logo}
              alt="logo"
            />
        
          }
          <CardContent>
          <Typography variant={responsive || short ? "body2" : "h5"} component="p" align={'left'} style={{color:COLORS.darkWhite, fontFamily:"Iceland"}}>
            We are an IT consulting company dedicated to making the process of getting high <i><b style={{color:COLORS.litGreen}}>quality</b></i> software development tasks accomplished <i><b style={{color:COLORS.litPink}}>simply</b></i>.  
            We believe that while a <i><b style={{color:COLORS.litPink}}>simple</b></i> solution may not always be possible, a <i><b style={{color:COLORS.litPink}}>simple</b></i> process should be.
          </Typography>
          </CardContent>
      </Card>
      {/* <img src={logo} alt="logo" style={{width:'40vw' }} /> */}

      {
        responsive ? 
        <Carousel> 
          {cardTitles.map(card=>{return getCards(card)})}
        </Carousel>
        
        :
      <Stack 
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        justifyContent="center"
        >
        {cardTitles.map(card=>{return getCards(card)})}

        </Stack>}
      </Stack>
  );

}
