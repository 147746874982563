
const homeBlerbs = {
    honesty:`At Lightside IT, honesty is the highest core value because it is the foundation of trust. When employees are honest with each other and with their customers, it creates trust and respect. 
    This trust is essential for building strong relationships and for creating a positive work environment. We pride ourselves on being ethical, direct, and honest.`,
    quality:`Quality is a non-negotiable core value at Lightside IT. We strive for a high quality product through communication, iteration, and accountability. Our staff takes pride in their work,
    striving for a quality product. Communication and iteration go hand and hand. We believe that showing you yhour product through out the development cycle is the best way to develop a quality product.`,
    simplicity:`At Lightside IT we believe that a simplicity is elegance and that an elegant solution is the best solution. Modularity, clarity, and focus results in sustainable future-proof solutions. 
    Simplicity is important for innovation, is important to be able to think outside the box and to avoid getting stuck in old patterns.`
}

export default homeBlerbs;