import {useState} from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import {COLORS} from '../constants/colors';
import logo from "../assets/img/lit_logo_dark.png";
import Typography from '@mui/material/Typography';

export default function Header(props) {
    const {responsive} = props;
    return (
        <Stack
            direction="row"
            sx={{height:'50px',backgroundColor:COLORS.litDarkBlue, width:responsive ? '100vw' : '50vw'}}
            >
            <Box>
            <img src={logo} alt="logo" style={{height:'50px', backgroundColor:"black"}} />
            </Box>
            <Tabs
                value={props.selectedTab} 
                onChange={props.onTabChange} 
                centered
                TabIndicatorProps={{
                    style: {
                        background: COLORS.litPink,
                        height: '5px'
                    }
                    }}
                >
                <Tab 
                label={
                    <Typography gutterBottom variant="body1" component="div" style={{fontFamily:"Iceland"}}>
                        Home
                    </Typography>
                } 
                value="home" style={{fontWeight: 'bold', color: props.selectedTab === 'home' ? COLORS.litPink : COLORS.litBlue}} />
                <Tab label={
                    <Typography gutterBottom variant="body1" component="div" style={{fontFamily:"Iceland"}}>
                        Services
                    </Typography>
                }  value="services" style={{fontWeight: 'bold', color: props.selectedTab === 'services' ? COLORS.litPink : COLORS.litBlue}} />
                <Tab label={
                    <Typography gutterBottom variant="body1" component="div" style={{fontFamily:"Iceland"}}>
                        About Us
                    </Typography>
                }  value="about" style={{fontWeight: 'bold', color: props.selectedTab === 'about' ? COLORS.litPink : COLORS.litBlue}} />
            </Tabs>
        </Stack>
    );
}