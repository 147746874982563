import {useState, useRef} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import servicesBlerbs from '../constants/blerbs/services';
import Grid from '@mui/material/Grid';

import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import developmentImg from '../assets/img/development_card.jpg';
import processReviewImg from '../assets/img/audit_card.jpg';
import projectManagementImg from '../assets/img/legacy_card.jpg';
import integrationImg from '../assets/img/integration_card.png';
import documentationImg from '../assets/img/documentation_card.jpg';
import adviceImg from '../assets/img/advice_card.jpg';

import {COLORS} from '../constants/colors';
export default function Services(props) {
  const short = props.short;
  const responsive = props.responsive;
  const developmentRef = useRef(null);
  const processRef = useRef(null);
  const projectRef = useRef(null);
  const integrationRef = useRef(null);
  const documentationRef = useRef(null);
  const technicalAdviceRef = useRef(null);
  const [clickedCard,setClickedCard] = useState(false);
  const [activeRef,setActiveRef] = useState();

  const cardTitles=[
    {
      title:'Development',
      short:'development',
      blerb:servicesBlerbs.blerbs.development,
      abstract:servicesBlerbs.abstracts.development,
      img:developmentImg,
      ref:developmentRef
    },
    {
      title:'Process Review',
      short:'processReview',
      blerb:servicesBlerbs.blerbs.processReview,
      abstract:servicesBlerbs.abstracts.processReview,
      img:processReviewImg,
      ref:processRef
    },
    {
      title:'Project Management',
      short:'projectManagement',
      blerb:servicesBlerbs.blerbs.projectManagement,
      abstract:servicesBlerbs.abstracts.projectManagement,
      img:projectManagementImg,
      ref:projectRef
    },
    {
      title:'Integration',
      short:'integration',
      blerb:servicesBlerbs.blerbs.integration,
      abstract:servicesBlerbs.abstracts.integration,
      img:integrationImg,
      ref:integrationRef
    },
    {
      title:'Review & Documentation',
      short:'documentation',
      blerb:servicesBlerbs.blerbs.documentation,
      abstract:servicesBlerbs.abstracts.documentation,
      img:documentationImg,
      ref:documentationRef
    },
    {
      title:'Technical Advice & Interviews',
      short:'technicalAdvice',
      blerb:servicesBlerbs.blerbs.technicalAdvice,
      abstract:servicesBlerbs.abstracts.technicalAdvice,
      img:adviceImg,
      ref:technicalAdviceRef
    }
  ]

  const getCards = (card) =>{
  return (
    responsive ? 
    <div>
      <Card ref={card.ref} sx={{width:"90%", margin:"5%"}}>
        <CardActionArea
          onClick={()=>{
            setClickedCard(false)
            setActiveRef(card.ref.current)
            setTimeout(()=>{setClickedCard(card.short)},100)
            
          }}
        >
          <CardMedia
            component="img"
            height="100"
            image={card.img}
            alt={card.title}
          />
          <CardContent >
            <Typography gutterBottom variant="h5" component="div" style={{fontFamily:"Iceland"}}>
              {card.title}
            </Typography>
            <Typography variant="body1" color="text.secondary" style={{fontFamily:"Iceland"}}>
              {card.abstract}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Typography variant="body1" component="div" align={'left'} style={{fontFamily:"Iceland", margin:"5%", color:COLORS.darkWhite}}>
        {card.blerb}
      </Typography>
    </div>
    :
    <Grid item xs={4}>
      <Card ref={card.ref} sx={{height:"340px"}}>
        <CardActionArea
          onClick={()=>{
            setClickedCard(false)
            setActiveRef(card.ref.current)
            setTimeout(()=>{setClickedCard(card.short)},100)
            
          }}
        >
          <CardMedia
            component="img"
            height="140"
            image={card.img}
            alt={card.title}
          />
          <CardContent >
            <Typography gutterBottom variant={responsive || short ? "body1" : "h4"} component="div" style={{fontFamily:"Iceland"}}>
              {card.title}
            </Typography>
            <Typography variant={responsive || short ? "body2" : "body1"} color="text.secondary" style={{fontFamily:"Iceland"}}>
              {card.abstract}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

    </Grid>
  )
  }

  return (
    <>
      {responsive ?
        <Carousel>
          {cardTitles.map(card=>{return getCards(card)})}
        </Carousel>
        :
        <>
          <Grid container spacing={2}  justifyContent={"center"} alignItems={"center"} direction={"row"}>
          {cardTitles.map(card=>{return getCards(card)})}
          {/* <Grid item xs={12} justifyContent={"center"} alignItems={"center"}>

          </Grid> */}
          </Grid>
          {clickedCard ? 
            <Slide 
            direction={short ? ['development','processReview','projectManagement'].includes(clickedCard) ? "down":"up" : "down"} //if not short, come direction down, if short switch based upon click
            in={clickedCard}
            container={activeRef}>
              <Paper
                style={{}}
                sx={{
                  position:"fixed", 
                  marginTop: "1vh",
                  top:!short ? 
                    "100" : 
                    ['development','processReview','projectManagement'].includes(clickedCard) ? 
                      "50%":
                      "35%", 
                  width:"50vw", 
                  backgroundColor: 'white'}}
                elevation={3}
                >
                  <IconButton
                  align={'left'}
                    style={{left:'45%'}}
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                    setClickedCard(false)
                    }}
                    >
                        <CloseIcon fontSize="inherit" />
                  </IconButton>

                  <Typography component="div" align={'left'} sx={{padding:'10px', fontSize: responsive || short ? "12pt" : "16pt"}} style={{fontFamily:"Iceland"}}>
                    {servicesBlerbs.blerbs[clickedCard]}
                  </Typography>

              </Paper>
            </Slide>:
            null
          }
    </>
    }
    </>
  );

}
