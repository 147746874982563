import {useState, useRef} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {COLORS} from '../constants/colors';
import tony from "../assets/img/tony.jpg";

export default function AboutUs(props) {
  const responsive = props.responsive;
  const short = props.short;
  return (
    <div style={{overflowY:"auto", height:"100%" }}>
      <Grid container spacing={2} alignContent="space-between" p={5} pb={15}>
        <Grid item xs={12} m={8}>
          <Typography align={"left"} sx={{ fontSize:responsive || short ? "14pt" : "20pt",color:COLORS.darkWhite}} >
            Lightside Information Technology was started in 2022 by Tony Baker with the intent of starting a great place to work
            that provides top quality services and honest, easy communication. We are based in Green Bay Wisconsin and are active in our community.
          </Typography>
        </Grid>
        { responsive ? null :
          <Grid item xs={12} m={4}>
            {/* <img src={logo} alt="logo" style={{height:'250px', backgroundColor:"black"}} /> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d79948.62974876376!2d-88.02889004438873!3d44.51221592880264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8802e2e809b380f3%3A0x6370045214dcf571!2sGreen%20Bay%2C%20WI!5e0!3m2!1sen!2sus!4v1680320544838!5m2!1sen!2sus" width="250" height="250" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Grid>
        }
        <Grid item xs={12} m={4}>
          <img loading="eager" src={tony} alt="Team" style={{borderRadius:50, width:'250px', backgroundColor:"black"}} />
        </Grid>
        <Grid item xs={12} m={8}>
          <Typography align={"left"} sx={{fontSize: responsive || short ? "14pt" : "20pt", color:COLORS.darkWhite}} >
            Tony Baker is the owner and senior consultant for Lightside and has an extensive network of experienced industry professionals available to help meet your needs.
          </Typography>
        </Grid>
      </Grid>
    </div>

  );

}
