import './App.css';
import Home from './pages/Home';
import Services from './pages/Services';
import AboutUs from './pages/AboutUs';
import Header from './components/Header';
import Footer from './components/Footer';

import {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import "@fontsource/iceland";
import homeBg from "./assets/img/homeBg.png";
import servicesBg from "./assets/img/servicesBg.png";
import aboutUsBg from "./assets/img/aboutUsBg.png";

import Modal from '@mui/material/Modal';

import advice_card from "./assets/img/advice_card.jpg";
import audit_card from "./assets/img/audit_card.jpg";
import development_card from "./assets/img/development_card.jpg";
import documentation_card from "./assets/img/documentation_card.jpg";
import honesty from "./assets/img/honesty.png";
import integration_card from "./assets/img/integration_card.png";
import legacy_card from "./assets/img/legacy_card.jpg";
import lit_logo_dark from "./assets/img/lit_logo_dark.png";
import lit_logo_light from "./assets/img/lit_logo_light.png";
import lit_logo_transparent from "./assets/img/lit_logo_transparent.png";
import lit_logo_transparent_full from "./assets/img/lit_logo_transparent_full.png";
import quality from "./assets/img/quality.png";
import register from "./assets/img/register.jpeg";
import simplicity from "./assets/img/simplicity.png";
import tony from "./assets/img/tony.jpg";
import loading_logo from "./assets/img/loading_logo.gif";
function App() {

  const imageList = [advice_card,
    audit_card,
    development_card,
    documentation_card,
    honesty,
    integration_card,
    legacy_card,
    lit_logo_dark,
    lit_logo_light,
    lit_logo_transparent,
    lit_logo_transparent_full,
    quality,
    register,
    simplicity,
    tony,  
  ]

  const [responsive, setResponsive] = useState(window.innerWidth > 800 ? false : true);
  const [short, setShort] = useState(window.innerHeight > 1100 ? false : true)
  const [selectedTab, setSelectedTab] = useState("home");
  const [changeBg, setChangeBg] = useState(true);
  const [slideDir,setSlideDir] = useState("left");
  const [showLoader, setLoader] = useState(true);

  const handleTabChange = (event, newValue) => {
    setSlideDir(slideDir === "left" ? "right" : "left")
    setChangeBg(false)
    setTimeout(()=>{setChangeBg(true)},250)
    setSelectedTab(newValue);
  };

  useEffect(()=>{
    cacheImages()

  },[])

  const cacheImages = async () => {
    const promises = await imageList.map((src)=>{
      return new Promise((resolve, reject)=>{
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      })
    })
    await Promise.all(promises)
    setTimeout(()=>{setLoader(false);}, 500)
    
  }

  const load_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -120,
    marginLeft: -120,
    borderRadius: '1000px',
    overflow: 'hidden',
    opacity:"100%",
    width:240,
    objectFit:'none',
    height:240,
    opacity: '100%',
  };

  return (
    <>
      { 
        showLoader ?
          <Modal
            open={true}
            // onClose={()=>{setOpen(false)}}
            style={{
            backgroundColor:"rgba(89,89,89,.8)",
            }}
            >
            <div style={load_style}>
              <img src={loading_logo} />
            </div>
        </Modal> :
      <div style={{backgroundColor:"black", overflow:"hidden"}}>
      <div className="App"
        style={{
          zIndex:99,
          position:"fixed",
          backgroundColor: "transparent",
          borderColor:"black",
          height:"99vh"
        }}
        >
          
        <div
          style={{
            marginLeft: responsive ? '0' : '25vw',
            position:"fixed",
            top:0,
            height:"100vh"
          }}
        >
          <Header 
            onTabChange={handleTabChange}
            selectedTab={selectedTab}
            responsive={responsive}
          />    
          <Paper sx={{ 
            height:'100%', 
            width: responsive ? '100vw':'50vw',
            backgroundColor: '#595959',
            overflowY:"auto",
            overflowX:"hidden"
            }}>
              {
              selectedTab === 'home' ? 
                <Home responsive={responsive} short={short} /> :
                selectedTab === 'services' ?
                  <Services responsive={responsive} short={short} /> : //services
                  selectedTab === 'about' ?
                    <AboutUs responsive={responsive} short={short} /> : //about us
                    null //add any additional pages here
              }
          </Paper>

          <Footer responsive={responsive} short={short} />
        </div>
      </div>

      <Slide style={{backgroundColor:"black", overflow:"hidden"}} in={changeBg} direction={slideDir} mountOnEnter unmountOnExit>
      <img 
        loading="lazy"
        src={selectedTab === "home"? homeBg :
          selectedTab === "services"? servicesBg :
          selectedTab === "about"? aboutUsBg :
          homeBg
        } 
        alt="" 
        style={{
          zIndex:1, 
          position:'absolute',
          top:0,
          opacity:.25,
          width:"100vw",
          height:"100vh",
          filter: "brightness(.8) saturate(1.4)",
          objectFit: "fill"
        }}
        />
      </Slide>

      </div>
          
    }
  </>
  );
}

export default App;
