
const servicesBlerbs = {
    abstracts:{
        development:`With over a decade of experience in supporting business needs, our team can handle your project. Mobile apps, websites, or user tools our team can create an intuitive and user friendly solution.`,
        
        processReview: `Allow us to review a complex or time consuming process and suggest potential technical solutions. We will document the process as well as the opportunities.`,
        
        projectManagement: `Finding someone with the technical, leadership, and organizational skills to manage your project is a challenge. Let us take on the complexities of your project and turn it into an actionable plan that can create a valueable product.`,
        
        integration: `Assistance setting up CI/CD or integrating systems through APIs, microservices, or other tools.`,
        
        documentation: `Reviewing code and providing documentation or suggestions.`,

        technicalAdvice:`A short Q&A session to explain your issue or ideas and get feedback.`,
    },
    blerbs:{
        development:`Our team has efficient development skills, a positive straight-to-the point attitude, and experience working directly with stakeholders.  
        This makes us an excellent fit to fill an IT gap at a small company or on a small team or support a project or development need on a large team. 
        We will enable your dream, project, or team to elevate to the next level.`,
        
        processReview: `Let our team of knowledgable problem solvers look at your companies pain points with a technical eye. We can suggest solutions with out of the box thinking and inventiveness. 
        We are solutions experts with excellent experience coordinating with business stakeholders on understanding their issues and converting those problem statements into solution statements.
        `,
        
        projectManagement: `Organizing a technical project is very similar to organizing any project, you need planning, accountability, and knowledge.  
        Let us bring our expertise to your project with an in depth project plan that provide realistic estimates, goals, and deadlines. 
        We focus on critical path, while keeping in close communication with business stakeholders to assist them in owning the product to ensure they get a solid product.
        `,
        
        integration: `Software integration is the process of combining two or more software applications so that they can work together seamlessly. This can be a complex and challenging process, but it is essential for businesses that want to improve their efficiency and productivity.
                    Our team can help you integrate your tools through APIs, microservices, and CI/CD.`,
        
        documentation: `Documentation is important in software development because it helps to ensure that the software is well-designed, well-built, and well-maintained. It also helps to communicate the software's purpose and functionality to users and other stakeholders.
        Likewise reviewing code for efficiency and future proofing is a valuable long term investment that will help the longevity of your projects. We will provide technical documentations about your codebase which will make your system more sustainable longterm.`,
        
        technicalAdvice:`Need a short meeting to field some questions, brainstorm, or vet a candidate, we are happy to help! Having outside opinions on software development projects can be very beneficial.
        It can help to identify potential problems, provide new ideas, challenge assumptions, and provide a fresh perspective. This can help to ensure that the project is successful and meets the needs of the stakeholders. 
        These engagements generally last two to eight hours, but can be structured to meet most needs.`,
    }
}




export default servicesBlerbs;